import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_b = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - B'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>B</h2>
     <LatQuotesIntro />
    
     <p><b>Beata Virgo (Maria)</b> - The Blessed Virgin (Mary)</p>
<p><b>Beatae memoriae</b> - Of blessed memory</p>
<p><b>Beati pacifici</b> - Blessed are the peacemakers</p>
<p><b>Beati pauperes spiritu</b> - Blessed are the poor in spirit</p>
<p><b>Beati possidentes</b> - The happy who possess. (Euripides)</p>
<p><b>Beatus</b> - The blessed one</p>
<p><b>Bella detesta matribus</b> - Wars, the horror of mothers. (Horace)</p>
<p><b>Bella gerant alii</b> - Let others wage war</p>
<p><b>Bellum omium contra omnes</b> - Everyman's war against everyman. (Thomas Hobbes)</p>
<p><b>Belua multorum es capitum</b> - You are a beast with many heads</p>
<p><b>Bene legere saecla vincere</b> - To read well is to master the ages. (Professor Isaac Flagg)</p>
<p><b>Bene qui latuit, bene vixit</b> - One who lives well, lives unnoticed. (Ovid)</p>
<p><b>Bene</b> - Good</p>
<p><b>Beneficium accipere libertatem est vendere</b> - To accept a favour is to sell freedom. (Publilius Syrus)</p>
<p><b>Bibere venenum in auro</b> - Drink poison from a cup of gold</p>
<p><b>Bis dat qui cito dat</b> - He gives twice who quickly gives. (Publius Syrus)</p>
<p><b>Bis in die (bid)</b> - Twice a day</p>
<p><b>Bis interimitur qui suis armis perit</b> - He is doubly destroyed who perishes by his own arms. (Syrus)</p>
<p><b>Bis repetita placent</b> - The things that please are those that are asked for again and again. (Horace)</p>
<p><b>Bis vincit qui se vincit in victoria</b> - He conquers twice who in the hour of conquest conquers himself. (Syrus)</p>
<p><b>Bis vivit qui bene vivit</b> - He lives twice who lives well</p>
<p><b>Bona fide</b> - In good faith. i. e. well</p>
<p><b>Bona fides (noun)</b> - Honest intention</p>
<p><b>Bona fortuna</b> - Good luck!</p>
<p><b>Bona officia</b> - Good services</p>
<p><b>Bonum commune communitatis</b> - General welfare. Literally, common good of the community</p>
<p><b>Bonum commune hominis</b> - Common good of man</p>
<p><b>Bonum vinum laetificat cor hominis</b> - Good wine gladdens a person's heart</p>
<p><b>Brevis ipsa vita est sed malis fit longior</b> - Our life is short but is made longer by misfortunes. (Publilius Syrus)</p>

   </Layout>
  )
}

export default LatQuotes_b
